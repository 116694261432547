@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Orbitron:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Orbitron" !important;
  background: #000 !important;
  min-height: 100vh !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c1022;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ac00fd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ac00fd;
}

/* 100VH to zoom */

.css-1eorin > div:nth-child(1) {
  height: 100%;
  height: 100vh !important;
}

.css-1ko7wd:focus-visible,
.css-1ko7wd[data-focus-visible] {
  box-shadow: none !important;
}

td {
  font-family: "Inter" !important;
}
